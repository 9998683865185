<template>
  <template v-if="readOnly">
    {{ valueInput }}
  </template>
  <a-input-number
    v-else
    class="w-100 ant-input-sm cds-input-number"
    v-model:value="valueInput"
    :size="size"
    :iconName="iconName"
    :placeholder="placeholder"
    :allowClear="allowClear"
    :disabled="disabled"
    autoComplete="off"
    @change="change"
    @press-enter="pressEnter"
  >
    <template v-if="icon" #addonAfter>
      <cds-feather-icons
        :type="icon"
        size="14"
        style="cursor: pointer"
        @click="iconClick(iconName)"
      />
    </template>
  </a-input-number>
</template>

<script>
import { defineComponent, ref } from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'InputNumber',
  props: {
    value: VueTypes.string.def(''),
    placeholder: VueTypes.string,
    allowClear: VueTypes.bool.def(true),
    disabled: VueTypes.bool.def(false),
    size: VueTypes.string.def('small'),
    icon: VueTypes.string,
    iconName: VueTypes.string,
    readOnly: VueTypes.bool.def(false),
  },
  emits: ['press-enter'],
  setup(props) {
    const valueInput = ref(props.value);
    return {
      valueInput,
    };
  },
  methods: {
    change(val) {
      this.$emit('update:value', val);
    },
    iconClick() {
      this.$emit('iconClick', this.iconName);
    },
    pressEnter(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit('press-enter');
    },
  },
  watch: {
    value(val) {
      this.valueInput = val;
    },
  },
});
</script>

<style>
.cds-input-number {
  background-color: #2B2A3A !important;
}
.cds-input-number .ant-input-number-handler-wrap {
  background: #2B2A3A !important;
}
.cds-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
  --tw-border-opacity: 1 !important;
  border-color: rgba(112, 111, 128, var(--tw-border-opacity)) !important;
}
.cds-input-number .ant-input-number-handler-wrap .ant-input-number-handler .anticon {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}
</style>
